import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { projectReducer, SingleProjectReducer } from './project/reducers';
import { fsCrudReducer, fsSingleCrudReducer } from './firebase/firestore/reducers';
import { operatorReducer } from './user/operator/reducers';
import { editorReducer } from './user/monteur/reducers';
import { clientReducer } from './user/client/reducers';
import { adminReducer } from './user/admin/reducers';
import { AIOReducer } from './aio/reducers';
import { alertReducer } from './alert/reducers'
import firebaseAuth from './firebase/auth/reducers';
import { generalConditionReducer } from './legal-text/general-conditions/reducers';
import { legalMentionReducer } from './legal-text/legal-mentions/reducers';
import { usageNoticeReducer } from './legal-text/usage-notices/reducers';
import { dahsboardReducer } from './dashbord/reducers'
import { fileReducer } from './file/reducers';

const rootReducers = combineReducers({
  fb: firebaseReducer,
  fs: firestoreReducer,
  auth: authReducer,
  projects: projectReducer,
  project: SingleProjectReducer,
  ChangeLayoutMode,
  crud: fsCrudReducer,
  singleCrud: fsSingleCrudReducer,
  operator: operatorReducer,
  editor: editorReducer,
  client: clientReducer,
  admin: adminReducer,
  AIO: AIOReducer,
  alert: alertReducer,
  firebaseAuth,
  condition: generalConditionReducer,
  mention: legalMentionReducer,
  notice: usageNoticeReducer,
  dashboard: dahsboardReducer,
  file: fileReducer
});

export default rootReducers;
