/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
const API_HOST = "http://api-logikko.prod-projet.com/" //"http://api.logikko.prod-projet.com/";
const API = {
  auth: {
    login: 'login',
    signUp: 'signup',
    refreshToken: 'token/refresh'
  },
  aio: {
    aioActive : 'api/aios/status/1',
    addAio : 'api/aios',
    aioInactive : 'api/aios',
    aioDetail: 'api/aios/{id}',
    aioCardData: 'api/aios/data/{id}',
    activateAio: 'api/aios/activate/{id}/{reference}',
    exportAio: 'api/export/inventories',
    exportActiveAio: 'api/export/aios',
    pollutionData: 'api/pollution/vehicle/',
    refuelingData: 'api/refueling/vehicle/',
    filterActive: 'api/aios/filter',
    statData: 'api/aios/month'
  },
  user: {
    admin: 'api/users/admin',
    client: 'api/users',
    deleteEditor: 'api/editor/',
    operator : 'api/operators',
    detailOperator : 'api/operators/{id}',
    editOperator : 'api/users/{id}',
    editor : 'api/editor',
    detailEditor: 'api/editor/',
    updatePassword: 'api/updatePassword/',
    aioEdited: 'api/intervention/editor/',
    exportEditor: 'api/export/editor',
    exportOperator: 'api/export/operator',
    exportUser: 'api/export/users',
    user: 'api/users',
    aioOperator: 'api/aio/operator/',
    filterOperator: 'api/operator/filter',
    filterUsers: 'api/users/filter'
  },
  legalText: {
    condition: 'api/terms_conditions',
    notice: 'api/legal_notice',
    manuals: 'api/user_manuals'
  },
  dashboard: {
    newsBlock: 'api/aio/count',
    recentEditors: 'api/editor'
  }
};

export { API_HOST, API };
