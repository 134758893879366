const actions = {
    ALL_OPERATOR_SUCCESS: 'ALL_OPERATOR_SUCCESS',
    EDIT_OPERATOR_SUCCESS: 'EDIT_OPERATOR_SUCCESS',
    DETAIL_OPERATOR_SUCCESS: 'DETAIL_OPERATOR_SUCCESS',
    ADD_OPERATOR_SUCCESS: 'ADD_OPERATOR_SUCCESS',
    EXPORT_OPERATOR_SUCCESS: 'EXPORT_OPERATOR_SUCCESS',
    ALL_AIO_OPERATOR_SUCCESS: 'ALL_AIO_OPERATOR_SUCCESS',
    DELETE_OPERATOR_SUCCESS: 'DELETE_OPERATOR_SUCCESS',
    FILTER_OPERATOR_SUCCESS: 'FILTER_OPERATOR_SUCCESS',
    /* action operator */
    allOperatorsSuccess: data => {
        return {
            type: actions.ALL_OPERATOR_SUCCESS,
            data,
        }
    },
    /* detail operator */
    detailOperatorSuccess: data => {
        return {
            type: actions.DETAIL_OPERATOR_SUCCESS,
            data,
        }
    },
    /* edit operator */
    filterSuccess: data => {
        return {
            type: actions.FILTER_OPERATOR_SUCCESS,
            data,
        }
    },
    /* edit operator */
    editOperatorSuccess: data => {
        return {
            type: actions.ALL_OPERATOR_SUCCESS,
            data,
        }
    },
    /* add operator */
    addOperatorSuccess: data => {
        return {
            type: actions.ADD_OPERATOR_SUCCESS,
            data,
        }
    },
    /* export operator */
    exportOperatorSuccess: data => {
        return {
            type: actions.EXPORT_OPERATOR_SUCCESS,
            data,
        }
    },
    /* all aio Operator */
    allAioOperatorSuccess: data => {
        return {
            type: actions.ALL_AIO_OPERATOR_SUCCESS,
            data
        }
    },
    /* delete operator */
    deleteOperatorSuccess: data => {
        return {
            type: actions.DELETE_OPERATOR_SUCCESS,
            data
        }
    },
}

export default actions