import actions from './actions';

const { ALL_AIO_ACTIF_SUCCESS, ALL_AIO_INACTIF_SUCCESS, SINGLE_AIO_SUCCESS, SINGLE_AIO_DATA_SUCCESS, ADD_AIO_SUCCESS, ACTIVATE_AIO_SUCCESS, EXPORT_AIO_SUCCESS, EXPORT_ACTIVE_AIO_SUCCESS, POLLUTION_DATA, REFUELING_DATA, STAT_DATA, FILTER_AIO_SUCCESS } = actions;

/* aio state */
const initialState = {
    aioActif: [],
    aioInactif: [],
    aioDetail: [],
    aioCardData: [],
    aioAdd: false,
    activateAIO: '',
    exportedData: [],
    pollutionData: [],
    filter: [],
    statisticData: []
}

/* aio reducer */
const AIOReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case ALL_AIO_ACTIF_SUCCESS:
            return { ...state, aioActif: data };
        case ALL_AIO_INACTIF_SUCCESS:
            return { ...state, aioInactif: data };
        case SINGLE_AIO_SUCCESS:
            return { ...state, aioDetail: data };
        case SINGLE_AIO_DATA_SUCCESS:
            return { ...state, aioCardData: data };
        case ADD_AIO_SUCCESS:
            return { ...state, aioAdd: data };
        case ACTIVATE_AIO_SUCCESS:
            return { ...state, activateAIO: data };
        case EXPORT_AIO_SUCCESS:
            return { ...state, exportedData: data };
        case EXPORT_ACTIVE_AIO_SUCCESS:
            return { ...state, exportedData: data };
        case FILTER_AIO_SUCCESS:
            return { ...state, filter: data };
        case POLLUTION_DATA:
            return { ...state, pollutionData: data };
        case REFUELING_DATA:
            return { ...state, refuelingData: data };
        case STAT_DATA:
            return { ...state, statisticData: data };
        default:
            return state;
    }
};
export { AIOReducer };