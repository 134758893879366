const actions = {
    ALL_CLIENTS_SUCCESS: 'ALL_CLIENTS_SUCCESS',
    EXPORT_CLIENT_SUCCESS: 'EXPORT_CLIENT_SUCCESS',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    FILTER_USER_SUCCESS: 'FILTER_USER_SUCCESS',

    /* action get all clients */
    allClientsSuccess: data => {
        return {
            type: actions.ALL_CLIENTS_SUCCESS,
            data,
        }
    },
    /* export client */
    exportClientSuccess: data => {
        return {
            type: actions.EXPORT_CLIENT_SUCCESS,
            data,
        }
    },
    /* add client */
    addUserSuccess: data => {
        return {
            type: actions.ADD_USER_SUCCESS,
            data,
        }
    },
    /* edit operator */
    filterSuccess: data => {
        return {
            type: actions.FILTER_USER_SUCCESS,
            data,
        }
    },
}

export default actions