import jwtDecode from "jwt-decode";

/* set token */
export const setToken = (token) => {
    localStorage.setItem("token", token);
}

/* set refresh token */
export const setRefreshToken = (token) => {
    localStorage.setItem("refreshToken", token);
}

/* get token */
export const getToken = () => {
    const token = localStorage.getItem("token") || null;
    return token
}

/* get refresh token */
export const getRefreshToken = () => {
    const token = localStorage.getItem("refreshToken") || null;
    return token
}

/* remove token */
export const removeToken = () => {
    localStorage.removeItem("token");
}

/* get Current Current User */
export const getUser = () => {
    const token = localStorage.getItem("token");
    return token ? jwtDecode(token) : null;
}

/* get Id Current User */
export const getUserId = () => {
    const user = getUser();
    return user?.id;
}

