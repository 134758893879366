import actions from './actions';

const { ADD_FILE_SUCCESS } = actions;

/* file state */
const initialState = {
    addFile: {},
}

/* aio reducer */
const fileReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
        case ADD_FILE_SUCCESS:
            return { ...state, addFile: data };
    default:
        return state;
    }
};
export { fileReducer };