import actions from "./actions";

const { ALL_CLIENTS_SUCCESS, EXPORT_CLIENT_SUCCESS, ADD_USER_SUCCESS, FILTER_USER_SUCCESS } = actions;

/* clients state */
const initialStateALL = {
    clients: [],
    exportedData: [],
    filter: [],
    userAdded: false
}

/* clients reducer */
const clientReducer = (state = initialStateALL, action) => {
    const { type, data } = action;
    switch (type) {
        case ALL_CLIENTS_SUCCESS:
            return {
                ...state,
                clients: data,
            };
        case EXPORT_CLIENT_SUCCESS:
            return {
                ...state,
                exportedData: data
            };
        case ADD_USER_SUCCESS:
            return {
                ...state,
                userAdded: data
            };
        case FILTER_USER_SUCCESS:
            return {
                ...state,
                filter: data
            };
        default:
            return state
    };
};

export { clientReducer };