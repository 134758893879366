const actions = {
    ADD_FILE_SUCCESS : 'ADD_FILE_SUCCESS',

    /* detail aio */
    addFileSuccess: data => {
        return {
            type: actions.ADD_FILE_SUCCESS,
            data,
        }
    }
}

export default actions;