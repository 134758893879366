import Cookies from 'js-cookie';
import actions from './actions';

const { LOGIN_SUCCESS, LOGOUT_SUCCESS } = actions;

/* authentication state */
const initState = {
  login: Cookies.get('logedIn')
};

/* authentication reducer */
const AuthReducer = (state = initState, action) => {
  const { type, data } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: data
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: data
      };
    default:
      return state;
  }
};
export default AuthReducer;
