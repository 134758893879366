const actions = {
    ALL_AIO_ACTIF_SUCCESS: 'ALL_AIO_ACTIF_SUCCESS',
    ALL_AIO_INACTIF_SUCCESS: 'ALL_AIO_INACTIF_SUCCESS',
    SINGLE_AIO_SUCCESS: 'GET_AIO_DETAIL',
    SINGLE_AIO_DATA_SUCCESS: 'GET_AIO_DATA_DETAIL',
    ADD_AIO_SUCCESS: 'ADD_AIO_SUCCESS',
    ACTIVATE_AIO_SUCCESS: 'ACTIVATE_AIO_SUCCESS',
    EXPORT_AIO_SUCCESS: 'EXPORT_AIO_SUCCESS',
    EXPORT_ACTIVE_AIO_SUCCESS: 'EXPORT_ACTIVE_AIO_SUCCESS',
    POLLUTION_DATA: 'POLLUTION_DATA',
    REFUELING_DATA: 'REFUELING_DATA',
    FILTER_AIO_SUCCESS: 'FILTER_AIO_SUCCESS',
    STAT_DATA: 'STAT_DATA',


    /* list aio actif */
    allAioActifSuccess: data => {
        return {
            type: actions.ALL_AIO_ACTIF_SUCCESS,
            data,
        }
    },

    /* list aio inactif */
    allAioInactifSuccess: data => {
        return {
            type: actions.ALL_AIO_INACTIF_SUCCESS,
            data,
        }
    },

    /* detail aio */
    singleAioSuccess: data => {
        return {
            type: actions.SINGLE_AIO_SUCCESS,
            data,
        }
    },

    /* data detail aio */
    singleAioDataSuccess: data => {
        return {
            type: actions.SINGLE_AIO_DATA_SUCCESS,
            data,
        }
    },

    /* detail aio */
    addAioSuccess: data => {
        return {
            type: actions.ADD_AIO_SUCCESS,
            data,
        }
    },

    /* activate aio */
    activateAioSuccess: data => {
        return {
            type: actions.ACTIVATE_AIO_SUCCESS,
            data
        }
    },
    /* export aio */
    exportAioSuccess: data => {
        return {
            type: actions.EXPORT_AIO_SUCCESS,
            data,
        }
    },
    /* export active aio */
    exportActiveAioSuccess: data => {
        return {
            type: actions.EXPORT_ACTIVE_AIO_SUCCESS,
            data,
        }
    },

    /* pollution data */
    pollutionData: data => {
        return {
            type: actions.POLLUTION_DATA,
            data,
        }
    },

    /* refueling data */
    refuelingData: data => {
        return {
            type: actions.REFUELING_DATA,
            data,
        }
    },
    /* filtering data */
    filterSuccess: data => {
        return {
            type: actions.FILTER_AIO_SUCCESS,
            data,
        }
    },
    /* statistic data */
    statisticData: data => {
        return {
            type: actions.STAT_DATA,
            data,
        }
    },
}


export default actions;