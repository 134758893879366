import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper } from './auth-info-style';
import { logOut } from '../../../redux/authentication/actionCreator';
import { fbAuthLogout } from '../../../redux/firebase/auth/actionCreator';

const AuthInfo = () => {
  const dispatch = useDispatch();
  const { isAuthenticate } = useSelector(state => {
    return {
      isAuthenticate: state.fb.auth.uid,
    };
  });

  const SignOut = e => {
    e.preventDefault();
    if (isAuthenticate) {
      dispatch(fbAuthLogout(dispatch(logOut())));
    } else {
      dispatch(logOut());
    }
  };
  return (
    <InfoWraper style={{position:"relative"}}>
      {/* <Notification /> */}
      <div className="nav-author">
        {/* <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
          </Link>
        </Popover> */}
        <Link style={{display:'flex',alignItems:'center'}} className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon style={{marginRight:'5px'}}  icon="log-out" size={16} />
          <span>Déconnexion</span>
        </Link>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
